export const environment = {
  production: false,
  /** CMW API URL */ 
  // APP_URL: "https://api.covermywork.com/api",
  // BLOB_URL: "https://api.covermywork.com/",
  // APP_NAME: "CoverMyWork",

  /** CC API URL */ 
  APP_URL: "https://api.coveragecatch.com/api",
  BLOB_URL: "https://api.coveragecatch.com/",
  APP_NAME: "CoverageCatch",


  /** CC API URL */ 
  // APP_URL: "https://bkpapi.covermywork.com/api",
  // BLOB_URL: "https://bkpapi.covermywork.com/",
  // APP_NAME: "covermywork",

  /** iFour API URL */ 
  // APP_URL: "http://api-covermywork.ifour-consultancy.net/api",
  // BLOB_URL: "http://api-covermywork.ifour-consultancy.net/",
  // APP_NAME: "CoverMyWork",

  /** Local API URL */ 
  // APP_URL: "http://localhost:63500/api",
  // BLOB_URL: "http://localhost:63500/",
  // APP_NAME: "CoverMyWork",

  /** Mobile support API URL (Forget Password) */ 
  WEB_URL: "test.covermywork.com",
};