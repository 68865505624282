import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Constants } from '../constant/constants';

@Component({
  selector: 'app-employeer',
  templateUrl: './employeer.component.html',
  styleUrls: ['./employeer.component.scss']
})
export class EmployeerComponent implements OnInit {
  isEmployeeRequestedOffer = false;
  isApprovedRequests = false;
  isTraningModule = false;
  isTimeOffRequest = false;
  isShowTimeOffRequest = false;
  isShowTraning = false;
  isShowClockInOut = false;
  isClockInOutModule = false;
  isShowCloseRequestOption = false;
  noModuleSubscribed: boolean;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;
  isShowSwap = false;
  isShowCallOff = false;
  isShowEarlyOut = false;
  isShowVot = false;
  isShowTransfer = false;
  isShowVto = false;
  isShowFlex = false;
  isShowLateIn = false;
  isShowReview = false;
  constructor() { }

  ngOnInit() {
    this.isEmployeeRequestedOffer = true;
    // this.isApprovedRequests = true;
    this.isShowTraning = JSON.parse(localStorage.getItem(Constants.IS_TRAINING));
    this.isShowTimeOffRequest = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isShowClockInOut = JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT));
    this.getEmployeerList(null)

  }

  getEmployeerList(data) {
    if (!!data) {
      this.isEmployeeRequestedOffer = false;
      this.isApprovedRequests = false;
      this.isTraningModule = false;
      this.isTimeOffRequest = false;
      this.isClockInOutModule = false;
      this.isShowCloseRequestOption = false;
      if (data === 1) {
        this.isEmployeeRequestedOffer = true;
        this.isShowCloseRequestOption = true;
      } else if (data === 2) {
        this.isApprovedRequests = true;

      } else if (data === 3) {
        this.isTraningModule = true;
      }
      else if (data === 4) {
        this.isTimeOffRequest = true;
      }
      else if (data === 5) {
        this.isClockInOutModule = true;
      }
    }
    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowCallOff = JSON.parse(localStorage.getItem(Constants.IS_CALL_OFF));
    this.isShowEarlyOut = JSON.parse(localStorage.getItem(Constants.IS_EARLY_OUT));
    this.isShowVot = JSON.parse(localStorage.getItem(Constants.IS_VOT));
    this.isShowTransfer = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER));
    this.isShowVto = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlex = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));
    this.isShowLateIn = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN));
    this.isShowReview = JSON.parse(localStorage.getItem(Constants.IS_REVIEW));
    if (this.isShowTimeOffRequest || this.isShowClockInOut || this.isShowSwap || this.isShowCallOff || this.isShowEarlyOut || this.isShowVot || this.isShowTransfer ||
      this.isShowVto || this.isShowFlex || this.isShowLateIn || this.isShowReview) {
      this.noModuleSubscribed = false;
    }
    else {
      this.noModuleSubscribed = true;
    }

    if (this.noModuleSubscribed) {
      this.noModule.nativeElement.innerHTML = "";
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
      this.noModule.nativeElement.innerHTML = "<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }
  }

}
