export class API {
  public static DEPARTMENT_ROUTES = {
    department: "/Department",
    departmentGetNew: "/Department/GetNew",
    saveDepartment: "/Department",
    deleteDepartment: "/Department/",
    getDepartmentByCompany: "/Department/GetByCompanyIdNew",
    dataFilter: "/Department/DataFilter",
    getDepartmentById: "/Department",
  };

  public static SUB_DEPARTMENT_ROUTES = {
    subDepartment: "/Department/GetSubDepartment",
    deleteSubDepartment: "/Department/DeleteSubDepartment",
    getSubDepartmentByCompany: "/Department/GetSubDepartmentByCompanyId",
    getSubDepartmentById: "/Department/GetSubDepartmentById",
    addSubDepartment: "/Department/AddSubDepartment",
    updateSubDepartment: "/Department/EditSubDepartment",
    subDepartmentDataFilter: "/Department/DataFilterSubDepartment",
    getSubDepartmentByDepartmentId:
      "/Department/GetSubDepartmentByDepartmentIdNew",
  };

  public static LOCATION_ROUTES = {
    location: "/Location",
    saveLocation: "/Location",
    deleteLocation: "/Location/",
    getLocationByCompany: "/Location/GetByCompanyIdNew",
    dataFilter: "/Location/GetAllDataFilter",
  };
  public static NOTIFICATION= {
    get: '/Notification',
    getNotificationCount: '/Notification/GetNotificationCount',
    sendNotification:"/Offer/SendNotificationWithEmail"
  }

  public static MODULE_ROUTES = {
    module: "/Module",
    addModule: "/Module/Post",
    updateModule: "/Module/Put",
    dataFilter: "/Module/dataFilter"
  };

  public static FUNCTION_ROUTES = {
    function: "/Function",
    addFunction: "/Function/Post",
    updateFunction: "/Function/Put",
    dataFilter: "/Function/dataFilter",
  };

  public static DEPARTMENT_MAPPING_ROUTES = {
    departmentMapping: '/DepartmentMapping',
    addDepartmentMapping: '/DepartmentMapping/Post',
    updateDepartmentMapping: '/DepartmentMapping/Put',
    dataFilter: '/DepartmentMapping/DataFilter',
  }

  public static TRAINING_ROUTE = {
    training: '/Training',
    addTraining: '/Training/Post',
    updateTraining: '/Training/Put',
    getModuleTraining: '/Training/GetAllModuleTraining',
    getFunctionTraining: '/Training/GetAllFunctionTraining',
    downloadModuleExcelFile: '/Training/ExportAllModuleTraining',
    downloadFunctionExcelFile: '/Training/ExportAllFunctionTraining',
    ModuledataFilter: '/Training/GetAllModuleTrainingDataFilter',
    FunctionDataFilter: '/Training/GetAllFunctionTrainingDataFilter',
    getUsertraining: '/Training/GetTrainings',
    userTrainingDataFilter: '/Training/UserTraninigDatFilter',
    userTrainingExport: '/Training/ExportAllTrainings',
    downloadFileData: '/Training/DownloadDocument'
  }

  public static ACTIVITY_LOG = {
    activityLog: '/ActivityLog',
    activityLogDataFilter: '/ActivityLog/ActivityDataFilter',
    downloadActivityExcel: '/ActivityLog/ExportAllActivityLogs',
    hrManagerDataList: '/ActivityLog/GetHrManagerData',
    hrManagerFilterData: '/ActivityLog/HrManagerFilterData',
    downloadHrManagerData: '/ActivityLog/ExportHrManagerTraining',
  }

  public static SHIFT_ROUTES = {
    shift: "/Shift",
    shiftNew: "/Shift/GetNew",
    shiftNewById: "/Shift/GetNewById",
    getShiftByCompany: "/Shift/GetByCompanyIdNew",
    getShiftByUser: "/Shift/Get",
    saveShift: "/Shift",
    deleteShift: "/Shift/",
    getShiftByDate: "/Shift/GetShiftByDate",
    getSkipShiftByDate: "/Shift/GetShiftBySkipDate",
    dataFilter: "/Shift/ShiftByCompanyDataFilter",
    getShiftByDepartment: "/Shift/GetShiftByDepartment",
    getTimeByShiftDateTime: "/Shift/GetShiftDetails",
    getShiftDetailsVot: "/Shift/GetShiftDetailsVot",
    getStartEndTimeByShift: "/Shift/GetStartEndTimeByShift",
  };

  public static COMPANY_ROUTES = {
    company: "/Company",
    get:"/Company/GetNew",
    getCompany: "/Company/GetCompanyByUsername",
    //saveCompany: '/Company',
    deleteCompany: "/Company/",
    dataFilter: "/Company/DataFilter",
  };

  public static LOGIN_ROUTES = {
    login: "/token",
    userData: "/User/GetUserClaim",
    loginAsUser: "/User/LogInAsUser",
    hrpendingRequestCount: "/Offer/RequestCountAllModule"
  };

  public static TERMS_CONDITION_ROUTES = {
    termsCondition: "/TermsAndConditions",
    termsConditionNew: "/TermsAndConditions/GetNew",
    // termsCondition: '/TermsAndConditions?page=&pageSize=',
    saveTermsCondition: "/TermsAndConditions",
    deleteTermsCondition: "/TermsAndConditions/",
    getTermsConditionByCompany: "/TermsAndConditions/GetByCompanyIdNew",
    dataFilter: "/TermsAndConditions/GetByCompanyDataFilter",
    updateTerms: "/User/UpdateTerms",
    getTerms: "/Pricing/GetTermsNew",
  };

  public static USER_ROUTE = {
    getUser: "/User",
    getUserByCompany: "/User/GetByCompanyId",
    addUser: "/User/AddUser",
    updateUser: "/User",
    deleteUser: "/User/",
    uploadFile: "/User/ImportEmployeeRoster",
    dataFilter: "/User/DataFilter",
    getAllManagerList: "/User/GetAllManagerList",
    getAllManagerListByCompany: "/User/GetAllManagerListByCompany",
    allDataFilter: "/User/GetAllDataFilter",
    getUserByUser: "/User/Get",
    getAllUserList: "/User/IfExistUserNew",
  };

  public static FORGOT_PASSWORD_ROUTES = {
    forgotPassword: "/User/ForgotPasswordNew",
  };

  public static RESET_PASSWORD_ROUTES = {
    // resetPassword: 'auth/reset-password',
    resetPassword: "/User/ChangePassword",
    recoveryPassword: "/User/ChangePasswordByEmailNew",
  };

  public static SIGN_UP_ROUTES = {
    signUp: "/User/RegisterNew",
    commonToken: "/User/GetCommonToken",
    isUserAuthenticate: "/User/CheckIfValidUserNew",
    getConfiguration: "/Company/GetConfigurationNew",
  };

  public static OFFER_ROUTES = {
    getMyOffers: "/Offer/UserRequests",
    deleteOffer: "/Offer/",
    addOffer: "/Offer/Post",
    updateOffer: "/Offer",
    availableOffer: "/Offer/AvailableOffer",
    acceptOffer: "/Offer/AcceptOffer",
    getRequestedOffer: "/Offer/GetApprovalRequests",
    approveOffer: "/Offer/ApproveOffer",
    rejectOffer: "/Offer/RejectOffer",
    getClosedRequest: "/Offer/GetClosedRequests",
    pendingRequest: "/Offer/UserHasPendingOffer",
    deleteOfferUser: "/Offer/DeleteAllForUser",
    availableOfferDataFilter: "/Offer/AvailableDataFilter",
    myRequestOfferDataFilter: "/Offer/MyRequestDataFilter",
    approvalOfferDataFilter: "/Offer/ApprovalRequestDataFilter",
    closedRequestOfferDataFilter: "/Offer/ClosedRequestsDataFilter",
    downloadPendingExcelFile: "/Offer/ExportPendingSwapExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedSwapExcel",
    getShiftWorkingDetails: "/Offer/GetShiftWorkingDetails",
    pendingUnregisterDevice: "/User/UnregisterDevice",
  };

  public static TIME_PUNCH_ROUTE = {
    getListByUser: "/TimePunches/GetListByUser",
    hrManagerTimePunches: "/TimePunches/GetListByCompanyId",
    userTrainingExport: "/TimePunches/ExportTimePunchesDetail",
    addTimePunches: "/TimePunches/AddTimePunches",
    editTimePunches: "/TimePunches/EditTimePunches",
    deleteTimePunches: "/TimePunches/Delete",
    timePunchesDataFilter: "/TimePunches/DataFilter",
    hrUserTimePunchesDataFilter: "/TimePunches/TimePunchesFilterData",
    authorizePage: "/TimePunches/GetHRAccessKiosk",
    addLaunchPunches: "/TimePunches/Punches",
    hrAddTimePunches: "/TimePunches/HrAddTimePunches",
    managerTimePunches: "/TimePunches/GetListByManager",
    managerTimePunchesDataFilter:"/TimePunches/GetListByManagerDataFilter",
    periodPayRoll:"/TimePunches/GetPeriodPayLoad",
    payRollTimePunchesFilter:"/TimePunches/GetPeriodPayLoadDataFilter",
    payrollReportExport:"/TimePunches/ExportPeriodPayLoadData",
    payrollReportDetail:"/TimePunches/GetListByPayrollReportClockInOut",
    // payrollReportClockOut:"/TimePunches/GetListByPayrollReportClockOut",
    payrollReportClockIn: "/TimePunches/GetListByPayrollReportClockIn",
    payrollReportClockOut: "/TimePunches/GetListByPayrollReportClockOut",
    userWeekHours:"/TimePunches/GetUserWeekHour",
    getInconsistTimePunches:"/TimePunches/GetPunchQuality",
    hrGetUserPendingRequests:"/TimePunches/HrGetUserPendingRequests",
    hrGetUserClosedRequests:"/TimePunches/HrGetUserClosedRequests",
    approveRejectUserRequests: "/TimePunches/ApproveRejectUserRequests",
    userEditRequest: "/TimePunches/UserEditRequest",
    hrGetUserPendingRequestsDataFilter: "/TimePunches/HrGetUserPendingRequestsDataFilter",
    hrGetUserClosedRequestsDataFilter: "/TimePunches/HrGetUserClosedRequestsDataFilter",
    removeTimePunchRequest: "/TimePunches/RemoveTimePunchRequest",
    resetUserIdentity: "/TimePunches/ResetUserIdentity",
  };

  public static TIME_PUNCH_EDIT_ROUTE = {
    editUserRequest: "/TimePunchesEdits/EditTimePunchRequest",
    addUserRequest: "/TimePunchesEdits/AddTimePunchRequest",
    getUserRequest: "/TimePunchesEdits/GetRequestsForUsers",
    getUserRequestDataFilter: "/TimePunchesEdits/GetRequestsForUsersDataFilter",
    hrGetUserPendingRequests:'/TimePunchesEdits/GetHrPendingRequests',
    GetHrClosedRequests:'/TimePunchesEdits/GetHrClosedRequests',
    approveRejectTimePuncheEditRequest:'/TimePunchesEdits/ApproveRejectTimePuncheEditRequest',
    hrGetUserPendingRequestsDataFilter:'/TimePunchesEdits/GetHrPendingRequestsDataFilter',
    hrGetUserClosedRequestsDataFilter:'/TimePunchesEdits/GetHrClosedRequestsDataFilter',
    hrGetUserClosedRequestsDownload:'/TimePunchesEdits/ExportClosedHrRequests',
    hrGetUserPendingRequestsDownload:'/TimePunchesEdits/ExportPendingHrRequests',
  }
  public static PLAN_ROUTES = {
    getPlan: "/Pricing/",
    getPlanNew: "/Pricing/GetNew",
    updatePlan: "/Pricing",
    getState: "/State",
    getUserByPricing: "/Pricing/Get",

  };

  public static CALL_OFF_REQUEST = {
    callOffReasons: "/Reason",
    callOffReasonsGetAll: "/Reason/GetAll",
    addCallOff: "/Offer/RequestCallOff",
    editCallOff: "/Offer/EditCallOffRequest",
    checkMonthlyCallOffRequest: "/Offer/GetNumberOfRequest",
    getUserList: "/Offer/UserCallOffRequests",
    userDataFilter: "/Offer/UserCallOffRequestsDataFilter",
    getClosedVotRequestOffer: "/Offer/GetCloseduserRequests",
    getCallOffReqestedList: "/Offer/GetApprovalCalloffRequests",
    approveCallOffOffer: "/Offer/ApproveCallOffOffers",
    approvalDataFilter: "/Offer/ApprovalCalloffDataFilter",
    closedDataFilter: "/Offer/ClosedCalloffDataFilter",
    callOffReasonFilterdata: "/Reason/CallOffReasonFilterdata",
    addCallOffReason: "/Reason/AddCallOffReason",
    downloadPendingExcelFile: "/Offer/ExportcallOffExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedCallOffExcel",
    getCallOffListForManager: "/Offer/GetCallOffListForManager",
    managerCallOffOfferDataFilter: "/Offer/ManagerCallOffDataFilter",
    getSettingByCompanyID: "/Offer/GetSettingByCompanyID",
  };


  public static CONTACT = {
    CONTACT_MESSAGE: "/ContactUs",
    CONTACT_MESSAGE_NEW: "/ContactUs/PostNew",
    SUBSCRIPTION: "/ContactUs/ScheduleDemoNew"
  }

  public static TIME_OFF_CONFIG = {
    timeOffGetAll: "/TimeOffConfiguration/GetAll",
    timeOffGetAllForUser: "/TimeOffConfiguration/GetAllConfigForUser",
    timeOffConfigTypesList: "/TimeOffConfigTypes/GetAll",
    addTimeOff: "/TimeOffConfiguration/AddTimeOffConfiguration",
    editTimeOff: "/TimeOffConfiguration/EditTimeOffConfiguration",
    deleteTimeOff: "/TimeOffConfiguration/DeleteTimeOffConfiguration",
    timeOffFilter: "/TimeOffConfiguration/TimeOffConfigurationFilterdata",
  }
  public static TIME_OFF_HR_REQUESTS = {
    addHrTimeOffRequest: "/TimeOffUserRequest/AddHrTimeOffUserRequest",
    updateHrTimeOffRequest: "/TimeOffUserRequest/EditTimeOffUserRequest",
    gethrUserRequests: "/TimeOffUserRequest/GetTimeOffUserRequestList",
    getPendingTimeOffRequestList: "/TimeOffUserRequest/GetAllPendingTimeOffRequestManager",
    getPendingHRTimeOffRequestList: "/TimeOffUserRequest/GetAllPendingTimeOffRequests",
    getPendingTimeOffRequestListFilterData: "/TimeOffUserRequest/GetAllPendingTimeOffRequestsDataFilter",
    getClosedTimeOffRequestList: "/TimeOffUserRequest/GetAllClosedTimeOffRequestManager",
    getClosedHRTimeOffRequestList: "/TimeOffUserRequest/GetAllClosedTimeOffRequests",
    getClosedTimeOffRequestListFilterData: "/TimeOffUserRequest/GetAllClosedTimeOffRequestsDataFilter",
    hrUserRequestsFilter: "/TimeOffUserRequest/TimeOffHRUserRequestFilterdata",
    deleteUserTimeOffRequest: "/TimeOffUserRequest/DeleteTimeOffUserRequest",
    approveDeniedUserRequest: "/TimeOffUserRequest/ApproveRejectTimeOffRequests",
    downloadClosedRequests: "/TimeOffUserRequest/ExportAllClosedTimeOffUserRequest",
    downloadPendingRequests: "/TimeOffUserRequest/ExportAllPendingTimeOffUserRequest",
    downloadSummaryRequests: "/TimeOffUserRequest/ExportTimeOffConfigurationSummary",
    downloadDetailsRequests: "/TimeOffUserRequest/ExportTimeOffConfigurationDetail",
    addTimeOffUserRequestHR:"/TimeOffUserRequest/AddTimeOffUserRequestHR",
    updateTimeOffUserRequestHR:"/TimeOffUserRequest/EditTimeOffUserRequestHR",
  };

  public static TIME_OFF_USER = {
    timeOffGetAllUser: "/TimeOffUserRequest/GetTimeOffUserNameRequestList",
    addTimeOffRequest: "/TimeOffUserRequest/AddTimeOffUserRequest",
    editTimeOffRequest: "/TimeOffUserRequest/EditTimeOffUserRequest",
    deleteTimeOffRequest: "/TimeOffUserRequest/DeleteTimeOffUserRequest",
    userDataFilter: "/TimeOffUserRequest/TimeOffUserRequestFilterdata",
    getAllPendingTimeOffRequestManagerFilter: "/TimeOffUserRequest/GetAllPendingTimeOffRequestManagerFilter",
    getAllClosedTimeOffRequestManagerDataFilter: "/TimeOffUserRequest/GetAllClosedTimeOffRequestManagerDataFilter"
  };

  public static EARLY_OUT_REQUEST = {
    addEarlyOutRequest: "/Offer/AddEarlyOutRequest",
    getUserEarlyOutRequestList: "/Offer/EarlyOutUserRequests",
    updateEarlyOutRequest: "/Offer/UpdateEarlyOutRequest",
    userDataFilter: "/Offer/EarlyOutUserRequestsDataFilter",
    avilableManagerEarlyOutOffers: "/Offer/AvailableManagerEarlyOutOffers",
    rejectManagerEarlyOutOffers: "/Offer/RejectEarlyOutOffers",
    approveManagerEarlyOutOffers: "/Offer/ApproveManagerEarlyOutOffers",
    availableManagerEarlyOutDataFilter:
      "/Offer/AvailableManagerEarlyOutDataFilter",
    closedManagerEarlyOutOffers: "/Offer/GetClosedManagerEarlyGoRequests",
    managerClosedEarlyOutDataFilter:
      "/Offer/GetClosedManagerEarlyGoRequestsFilter",
    hrPendingRequests: "/Offer/UnprocessedEarlyOutRequest",
    hrClosedRequests: "/Offer/ClosedEarlyOutRequest",
    hrPendingEarltyOutOfferDataFilter:
      "/Offer/UnprocessedEarlyOutRequestDataFilter",
    hrClosedEarltyOutOfferDataFilter: "/Offer/ClosedEarlyOutRequestDataFilter",
    proceedHroffer: "/Offer/ProcessSingleOffer",
    rejectHrEarlyOutOffers: "/Offer/RejectEarlyOutOffers",
    downloadPendingExcelFile: "/Offer/ExportEarlyOutExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedEarlyOutExcel",
  };
  public static EMPLOYEE_ROSTER = {
    EmployeeRoster: "/EmployeeRoster",
    dataFilter: "/EmployeeRoster/DataFilter",
  };
  public static OFFER_LOG = {
    OfferLogEntry: "/Offer/OfferLogEntry",
  };
  public static INVOICE_ROUTES = {
    getInvoice: "/Invoice/GetListByCompanyId",
    updateInvoice: "/Invoice",
    dataFilter: "/Invoice/InvoiceByCompanyDataFilter",
    printInvoice: "/Invoice/GeneratePDF",
  };
  public static MARKET_CAMPAIGN = {
    add: "/MarketCampaign/Add",
  };

  public static VOT_REQUEST_ROUTES = {
    getVotRequest: "/Offer/UserVotRequests",
    addVotRequest: "/Offer/AddVotRequest",
    updateVotRequest: "/Offer/UpdateVotRequest",
    votRequestOfferDataFilter: "/Offer/UserVotDataFilter",
    getHrVotRequest: "/Offer/GetApprovalVotRequests",
    getVotHours: "/Offer/GetApproveVothours",
    approveVotOffer: "/Offer/ApproveVotOffers",
    rejectVotOffer: "/Offer/RejectVotOffers",
    getClosedVotRequestOffer: "/Offer/GetClosedVotRequests",
    approveVotDataFilter: "/Offer/ApprovalVotDataFIlter",
    closedVotDataFilter: "/Offer/ClosedVotFilterData",
    downloadExcel: "/Offer/ExportExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedVotExcel",
  };

  public static VTO_REQUEST_ROUTES = {
    addVtoRequest: "/Offer/AddVtoRequest",
    getVtoRequest: "/Offer/UserVtoRequests",
    vtoRequestOfferDataFilter: "/Offer/UserVtoRequestsDataFilter",
    updateVtoRequest: "/Offer/UpdateVtoRequest",
    availableVtoRequest: "/Offer/AvailableVtoOffer",
    availableVtoOfferDataFilter: "/Offer/AvailableVtoDataFilter",
    acceptVtoOffer: "/Offer/AcceptVtoOffer",
    approveVtoOffer: "/Offer/ApproveVtoOffer",
    rejectVtoOffer: "/Offer/RejectVtoOffer",
    getPendingApprovalRequest: "/Offer/GetApprovalVtoRequests",
    pendingApprovalRequestDataFilter: "/Offer/ApprovalVtoDataFilter",
    getVtoClosedRequest: "/Offer/GetClosedVtoRequests",
    vtoClosedRequestDataFilter: "/Offer/ClosedVtoDataFilter",
    getUnProcessedVtoRequest: "/Offer/UnprocessedVtoRequest",
    availableManagerVtoOffers: "/Offer/AvailableManagerVtoOffers",
    vtoManagerAvailableRequestDataFilter:
      "/Offer/AvailableManagerVtoDataFilter",
    approveRejectMangerVtoOffer: "/Offer/ApproveRejectVto",
    processVtoRequest: "/Offer/ProcessOffer",
    unProcessedVtoOfferDataFilter: "/Offer/UnprocessedVtoDataFilter",
    instantVtoRequest: "/Offer/InstantVtoRequest",
    downloadPendingVtoFile: "/Offer/ExportVtoExcel",
    downloadClosedVtoFile: "/Offer/ExportClosedVtoExcel",
  };

  public static FLEX_REQUEST_ROUTES = {
    addFlexRequest: "/Offer/AddFlexWorkRequest",
    getFlexRequest: "/Offer/UserFlexWorkRequests",
    flexRequestOfferDataFilter: "/Offer/UserFlexWorkRequestsDataFilter",
    updateFlexRequest: "/Offer/UpdateFlexWorkRequest",
    availableFlexRequest: "/Offer/AvailableFlexWorkOffer",
    availableFlexOfferDataFilter: "/Offer/AvailableFlexWorkDataFilter",
    acceptFlexOffer: "/Offer/AcceptFlexWorkOffer",
    approveFlexOffer: "/Offer/ApproveVtoOffer",
    rejectFlexOffer: "/Offer/RejectVtoOffer",
    // getPendingApprovalRequest: '/Offer/GetApprovalVtoRequests',
    // pendingApprovalRequestDataFilter: '/Offer/ApprovalVtoDataFilter',
    getFlexClosedRequest: "/Offer/GetClosedFlexWorkRequests",
    flexClosedRequestDataFilter: "/Offer/GetClosedFlexWorkDataFilter",
    // getUnProcessedVtoRequest: '/Offer/UnprocessedVtoRequest',
    availableHrAdminFlexOffers: "/Offer/AvailableHRFlexWorkOffers",
    flexHrAdminAvailableRequestDataFilter:
      "/Offer/AvailableHRFlexWorkDataFilter",
    approveRejectHrAdminFlexOffer: "/Offer/ApproveRejectFlexWork",
    processFlexRequest: "/Offer/ProcessFlexWorkOffer",
    // unProcessedVtoOfferDataFilter: '/Offer/UnprocessedVtoDataFilter',
    instantFlexRequest: "/Offer/InstantFlexWorkRequest",
    // downloadPendingVtoFile: '/Offer/ExportVtoExcel',
    downloadClosedFlexFile: "/Offer/ExportClosedFlexWorkExcel",
  };

  public static SETTING_ROUTES = {
    addSetting: "/Setting/AddSetting",
    updateSetting: "/Setting/AddSetting",
    getSetting: "/Setting/GetSetting",
    dataFilter: "/Setting/DataFilter",
  };
  public static TRANSFER_REQUEST_ROUTES = {
    addTransferRequest: "/Offer/AddTransferRequest",
    updateTransferRequest: "/Offer/UpdateTransferRequest",
    getUserTransferRequestList: "/Offer/GetTransferRequestsForUser",
    closedManagerTransferOffers: "/Offer/GetClosedManagerTransferRequests",
    downloadPendingExcelFile: "/Offer/ExportTransferExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedTransferExcel",
    hrTransferPendingRequests: "/Offer/GetTransferRequestsForHrandManager",
    hrTransferClosedRequests: "/Offer/GetClosedTransferRequests",
    hrPendingTransferOfferDataFilter: "/Offer/GetTransferRequestsDataFilter",
    hrClosedTransferOfferDataFilter: "/Offer/GetClosedTransferWorkDataFilter",
    approveTransferRequest: "/Offer/ApproveTransferRequestByHr",
    approveDenialRequest: "/Offer/DenyTransferRequestByHr",
    acceptTransferRequest: "/Offer/AcceptTransferRequestByUser",
    rejectTransferRequestByUser: "/Offer/RejectTransferRequestByUser",
    transferProcessRequest: "/Offer/ProcessTransferOffer",
    transferRequestForUserDataFilter:
      "/Offer/GetTransferRequestsForUserDataFilter",
  };

  public static LATE_IN_REQUEST = {
    addLateInRequest: "/Offer/AddLateInRequest",
    getUserLateInRequestList: "/Offer/LateInUserRequests",
    updateLateInRequest: "/Offer/UpdateLateInRequest",
    userDataFilter: "/Offer/LateInUserRequestsDataFilter",
    avilableManagerLateInOffers: "/Offer/AvailableManagerLateInOffers",
    rejectManagerLateInOffers: "/Offer/RejectLateInOffers",
    approveManagerLateInOffers: "/Offer/ApproveManagerLateInOffers",
    availableManagerLateInDataFilter:
      "/Offer/AvailableManagerLateInDataFilter",
    closedManagerLateInOffers: "/Offer/GetClosedManagerLateInRequests",
    managerClosedLateInDataFilter:
      "/Offer/GetClosedManagerLateInRequestsFilter",
    hrPendingLateInRequests: "/Offer/UnprocessedLateInRequest",
    hrClosedRequests: "/Offer/ClosedLateInRequest",
    hrPendingLateInOfferDataFilter:
      "/Offer/UnprocessedLateInRequestDataFilter",
    hrClosedLateInOfferDataFilter: "/Offer/ClosedLateInRequestDataFilter",
    proceedHroffer: "/Offer/ProcessSingleOffer",
    rejectHrLateInOffers: "/Offer/RejectLateInOffers",
    downloadPendingExcelFile: "/Offer/ExportLateInExcel",
    downloadClosedExcelFile: "/Offer/ExportClosedLateInExcel",
    approveLateInDataFilter: "/Offer/UnprocessedLateInRequestDataFilter",
    closedLateInDataFilter: "/Offer/ClosedLateInRequestDataFilter",
    lateInManagerAvailableRequestDataFilter:
      "/Offer/AvailableManagerLateInDataFilter",
    availableLateInOfferDataFilter: "/Offer/AvailableLateInDataFilter",
  };
  public static KIOSK = {
    getKioskList: "/Kiosk/GetAll",
    deleteOfferUser: "/Kiosk/Delete",
    addKioskRequest: "/Kiosk/AddKiosk",
    updateKioskRequest: "/Kiosk/EditKiosk",
    kioskDataFilter: "/Kiosk/DataFilter",
  };


  public static LAUNCH_KIOSK = {


  };

  public static EMPLOYEE_REVIEW_REQUEST = {
    addReviewRequest: "/EmployeeReview/AddEmployeeReviewUser",
    getUserReviewRequestList: "/EmployeeReview/GetEmployeeReviewList",
    ReviewClosedManagerRequestList: "/EmployeeReview/GetEmployeeReviewClosedHr",
    hrPendingReviewRequests: "/EmployeeReview/GetEmployeeReviewPendingHr",
    downloadPendingExcelFile: "/EmployeeReview/ExportEmployeeReviewPendingDetail",
    downloadClosedExcelFile: "/EmployeeReview/ExportEmployeeReviewClosedDetail",
    updateReviewRequest: "/EmployeeReview/EditEmployeeReviewUser",
    userDataFilter: "/EmployeeReview/UserDataFilter",
    managerPendingDataFilter: "/EmployeeReview/ManagerPendingDataFilter",
    managerClosedDataFilter: "/EmployeeReview/ManagerClosedDataFilter",
    reviewPendingDataFilter: "/EmployeeReview/GetEmployeeReviewPendingHrDataFilter",
    reviewClosedDataFilter: "/EmployeeReview/GetEmployeeReviewClosedHrDataFilter",
    deleteReviewRequest: "/EmployeeReview/Delete",
  };

  public static FEEDBACK_ROUTES = {
    addFeedbackRequest: "/IssueReport/SendReport"
  }
}