import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TermsConditionComponent } from '../terms-condition/terms-condition.component';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { InputTrimModule } from 'ng2-trim-directive';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IMaskModule } from 'angular-imask';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { CoverageTermsConditionComponent } from './coverage-terms-condition/coverage-terms-condition.component';
import { AcceptEarlyGoRequestComponent } from '../../offer/approve-proceed-hr/accept-early-go-request.component';
// import { SignaturePadModule } from 'angular2-signaturepad';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FlexRequestComponent } from './flex-request/flex-request.component';
import { FlexRequestService } from 'src/app/service/flex-request.service';
import { RecaptchaModule} from "ng-recaptcha";
import { UserTrainingInfoComponent } from 'src/app/user-training-info/user-training-info.component';
import { HrManagerTimeOffRegisterComponent } from './../../employeer/hr-manager-time-off-register/hr-manager-time-off-register.component';
import { LocationMapComponent } from 'src/app/location-map/location-map.component';
import { LocationService } from 'src/app/service/location.service';
import { ClockinClockoutComponent } from 'src/app/clockin-clockout/clockin-clockout.component';
import { AddEditReviewRequestComponent } from 'src/app/offer/add-edit-review-request/add-edit-review-request.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [AddEditReviewRequestComponent ,ClockinClockoutComponent,LocationMapComponent,HrManagerTimeOffRegisterComponent,UserTrainingInfoComponent ,ConfirmationComponent, TermsConditionComponent, ValidationMessageComponent, CoverageTermsConditionComponent, AcceptEarlyGoRequestComponent, FlexRequestComponent],
  imports: [
  CommonModule,
    CollapseModule,
    ModalModule.forRoot(),
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxDatatableModule,
    TimepickerModule,
    InputTrimModule,
    NgxFileDropModule,
    IMaskModule,
    PasswordStrengthMeterModule,
    PdfViewerModule,
    CKEditorModule,
    RecaptchaModule,
    FullCalendarModule
  ],
  providers: [BsModalService, FlexRequestService,LocationService],
  exports: [CommonModule,
    CollapseModule,
    ModalModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationComponent,
    TimepickerModule,
    NgbModule,
    ConfirmationComponent,
    NgxDatatableModule,
    TermsConditionComponent,
    ValidationMessageComponent,
    InputTrimModule,
    NgxFileDropModule,
    IMaskModule,
    LocationMapComponent,
    PasswordStrengthMeterModule,
    CoverageTermsConditionComponent,
    AcceptEarlyGoRequestComponent,
    PdfViewerModule,
    CKEditorModule,
    FlexRequestComponent,
    RecaptchaModule,
    UserTrainingInfoComponent,
    HrManagerTimeOffRegisterComponent,
    ClockinClockoutComponent,
    AddEditReviewRequestComponent
    // SignaturePadModule ,
  ]
})
export class SharedModule { }
